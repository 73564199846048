<template>
    <q-item>
        <q-item-section side top>
            <q-checkbox v-model="extra._selected" @update:model-value="$emit('update')"/>
        </q-item-section>
        <q-item-section>
            <q-item-label> {{ extra.name }} </q-item-label>
            <q-item-label caption> {{ extra.description }}  </q-item-label>
        </q-item-section>
        <q-item-section side>
            <q-item-label> {{ extra.price }} €</q-item-label>
        </q-item-section>
    </q-item>
</template>

<script>
export default {
    name: 'EventExtraDisplay',
    props: {
        extra: Object
    },
    emits: ["update"]
}
</script>
