import { ForeignKey, ManyToMany } from "@/models/fields.js";
import BaseModel from "@/models/base-model.js";
import User from "@/models/user/user.js";
import EventModel from "@/models/event/event.js";
import EventPrice from "@/models/event/eventprice.js";
import EventExtra from "@/models/event/eventextra.js";

export default class EventRegistration extends BaseModel {
    static describe () {
        return Object.assign({},
            BaseModel.describe(),
            {
                "price_category": {
                    type: ForeignKey(EventPrice)
                },
                "extras": {
                    type: ManyToMany(EventExtra)
                },
                "user": {
                    type: ForeignKey(User)
                },
                "event": {
                    type: ForeignKey(EventModel)
                }
            }
        );
    }

    static get api_name () {
        return "event.eventregistration";
    }
}

